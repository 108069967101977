import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import InstagramIcon from 'components/icons/Instagram'
import FacebookIcon from 'components/icons/Facebook'
import PhoneIcon from 'components/icons/Phone'
import horizontalLogoDarkBg from 'static/logos/horizontal-logo-dark-bg.png'
import OfficeGoogleMap from 'components/OfficeGoogleMap'

const StyledFooter = props => (
  <footer
    className="text-offWhite bg-cyan py-12 justify-center self-end"
    {...props}
  />
)

const Container = props => (
  <div
    className="container mx-auto px-4 flex sm:flex-col md:flex-row"
    {...props}
  />
)

const FooterSection = props => (
  <div className="flex flex-col flex-1 text-2xl" {...props} />
)

const LogoContainer = props => <div className="flex" {...props} />

const StyledLink = props => (
  <Link
    className="text-offWhite block no-underline text-sm leading-snug"
    {...props}
  />
)

const Address = props => (
  <div className="text-offWhite block text-sm mb-4" {...props} />
)

const ExternalLink = props => (
  <a className="text-offWhite block text-sm no-underline" {...props} />
)

const Hour = props => <div className="text-sm leading-snug" {...props} />

const Footer = () => (
  <Fragment>
    <OfficeGoogleMap isMarkerShown />
    <StyledFooter>
      <Container>
        <FooterSection>
          <h3 className="text-sm font-bold mb-4">Menu</h3>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/about_us">About Us</StyledLink>
          <StyledLink to="/appointments">Appointments</StyledLink>
          <StyledLink to="/emergency_care">Emergency Care</StyledLink>
          <StyledLink to="/treatments">Treatments</StyledLink>

          <StyledLink to="/faqs">FAQs</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>
        </FooterSection>

        <FooterSection>
          <h3 className="text-sm font-bold mb-4">Hours</h3>
          <Hour>MO 8:00 AM - 5:00 PM</Hour>
          <Hour>TU 8:00 AM - 5:00 PM</Hour>
          <Hour>WE 8:00 AM - 5:00 PM</Hour>
          <Hour>TH 8:00 AM - 5:00 PM</Hour>
          <Hour>FR 8:00 AM - 1:00 PM</Hour>
          <Hour>SA Closed</Hour>
          <Hour>SU Closed</Hour>
        </FooterSection>

        <FooterSection>
          <Address>19731 South. Hwy 213</Address>
          <ExternalLink href="tel: 503-518-3384">
            <PhoneIcon
              className="inline-block mr-1"
              size="2rem"
              fill="currentColor"
            />
            503-518-3384
          </ExternalLink>
          <ExternalLink href="https://www.facebook.com/Oregon-City-Smiles-328506251331414">
            <FacebookIcon
              className="inline-block mr-1"
              size="2rem"
              fill="currentColor"
            />
            Facebook
          </ExternalLink>
          <ExternalLink href="https://www.instagram.com/oregoncitysmiles">
            <InstagramIcon
              className="inline-block mr-1"
              size="2rem"
              fill="currentColor"
            />
            Instagram
          </ExternalLink>
          <LogoContainer>
            <img
              style={{ height: '50px' }}
              src={horizontalLogoDarkBg}
              alt="Oregon City Smiles"
            />
          </LogoContainer>
        </FooterSection>
      </Container>
    </StyledFooter>
  </Fragment>
)

export default Footer
