import React, { useState } from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Nav from 'components/Nav/Nav'
import Footer from 'components/Footer/Footer'
import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import './layout.css'
import './normalize.css'

const Stage = props => <section {...props} />

let open = true

const _setOpen = val => (open = val)

const Layout = ({ children }) => {
  const [, hideCovidBanner] = useState(false)
  const setOpen = val => {
    hideCovidBanner(!val)
    _setOpen(val)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Stage>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'Oregon City Smiles Dental is a family dental office providing quality dental care for all ages including general dental treatments,  dental emergency, implants, cosmetic dental treatments, Invisalign and dentistry for kids.'
              },
              {
                name: 'keywords',
                content:
                  'dentist, dental, oregon city dentist, dentist near me, best dentists near me, 213, oregon city dentistry, oregon city dental, oregon city family dentistry, oregon city dental office, oregon city smiles dental, oregon city, smiles, dental, dentist, dentists oregon city, best dentist oregon city, invisalign, oregon city, implant oregon city, teeth whitening, best dentist near me, dental office winco plaza, toothache, kids dentist, root canal, veneers'
              },
              {
                name: 'geo.region',
                content: 'US-OR'
              },
              {
                name: 'geo.placename',
                content: 'Oregon City'
              },
              {
                name: 'geo.position',
                content: '45.320322,-122.5802065'
              },
              {
                name: 'ICBM',
                content: '45.320322,-122.5802065'
              }
            ]}
          >
            <html lang="en" />
          </Helmet>
          {false && (
            <div className="bg-cyan text-offWhite p-2">
              <div className="container mx-auto flex items-center justify-between">
                <div className="flex items-center">
                  <ErrorOutlineIcon />
                  <Link
                    to="/covid_response"
                    className="text-sm text-offWhite my-2 pl-4"
                    onClick={() => setOpen(false)}
                  >
                    Our response to COVID-19. We are reopen starting May 4th.
                  </Link>
                </div>
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
            </div>
          )}
          <Nav />
          {children}
          <Footer />
        </Stage>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
