import React, { Component } from 'react'
import Link from 'gatsby-link'
import Hamburger from 'components/icons/Hamburger'
import horizontalLogo from 'static/logos/horizontal-logo.png'

const NavMenuButtonWrapper = ({ children, noBorder }) => (
  <div className={`border-offWhite ${noBorder ? 'border-b-0' : 'border-b'}`}>
    {children}
  </div>
)

const MobileMenuContainer = ({ children }) => (
  <div
    className="absolute rounded shadow-xl p-4 z-10 select-none bg-white right-0 mt-6"
    style={{ width: 300 }}
  >
    {children}
  </div>
)

class Nav extends Component {
  state = {
    open: false
  }

  toggleMobileMenu = () => {
    const { open } = this.state

    this.setState({
      open: !open
    })
  }

  close = () => {
    this.setState({ open: false })
  }

  componentDidUpdate() {
    if (this.state.open) {
      document.addEventListener('click', this.close)
    } else {
      document.removeEventListener('click', this.close)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.close)
  }

  render() {
    const { open } = this.state

    return (
      <nav className="flex items-center py-1" style={{ minHeight: 60 }}>
        <div className="absolute">
          <Link to="/">
            <div className="my-2 mx-4">
              <img src={horizontalLogo} alt="Logo" style={{ height: '50px' }} />
            </div>
          </Link>
        </div>

        <div className="hidden md:flex flex-1 flex-row justify-center items-center">
          <Link className="text-lg p-4" to="/">
            Home
          </Link>
          <Link className="text-lg p-4" to="/about_us">
            About Us
          </Link>
          <Link className="text-lg p-4" to="/emergency_care">
            Emergency Care
          </Link>
          <Link className="text-lg p-4" to="/appointments">
            Appointments
          </Link>
          <Link className="text-lg p-4" to="/treatments">
            Treatments
          </Link>
        </div>

        <div className="block md:hidden absolute right-0 mx-4">
          <div onClick={this.toggleMobileMenu}>
            <Hamburger />
          </div>
          {open && (
            <MobileMenuContainer>
              <NavMenuButtonWrapper>
                <Link className="block text-lg p-4" to="/">
                  Home
                </Link>
              </NavMenuButtonWrapper>
              <NavMenuButtonWrapper>
                <Link className="block text-lg p-4" to="/about_us">
                  About Us
                </Link>
              </NavMenuButtonWrapper>
              <NavMenuButtonWrapper>
                <Link className="block text-lg p-4" to="/emergency_care">
                  Emergency Care
                </Link>
              </NavMenuButtonWrapper>
              <NavMenuButtonWrapper>
                <Link className="block text-lg p-4" to="/appointments">
                  Appointments
                </Link>
              </NavMenuButtonWrapper>
              <NavMenuButtonWrapper noBorder>
                <Link className="block text-lg p-4" to="/treatments">
                  Treatments
                </Link>
              </NavMenuButtonWrapper>
            </MobileMenuContainer>
          )}
        </div>
      </nav>
    )
  }
}

export default Nav
