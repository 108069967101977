import React from 'react'
import { compose, withProps } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'

const OfficeGoogleMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCRILPPq7UC6epod3dAnDTsIaPUPiAxz5U&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 45.320322, lng: -122.5802065 }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: 45.320322, lng: -122.5802065 }} />
    )}
  </GoogleMap>
))

export default OfficeGoogleMap
