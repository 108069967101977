import React from 'react'

const Hamburger = props => (
  <svg
    viewBox="0 0 17 20"
    width="2rem"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>hamburger</title>
    <path d="M17.143 15v1.429c0 0.391-0.324 0.714-0.714 0.714h-15.714c-0.391 0-0.714-0.324-0.714-0.714v-1.429c0-0.391 0.324-0.714 0.714-0.714h15.714c0.391 0 0.714 0.324 0.714 0.714zM17.143 9.286v1.429c0 0.391-0.324 0.714-0.714 0.714h-15.714c-0.391 0-0.714-0.324-0.714-0.714v-1.429c0-0.391 0.324-0.714 0.714-0.714h15.714c0.391 0 0.714 0.324 0.714 0.714zM17.143 3.571v1.429c0 0.391-0.324 0.714-0.714 0.714h-15.714c-0.391 0-0.714-0.324-0.714-0.714v-1.429c0-0.391 0.324-0.714 0.714-0.714h15.714c0.391 0 0.714 0.324 0.714 0.714z" />
  </svg>
)

export default Hamburger
